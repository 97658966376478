@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
    .line-clamp-3 {
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
  }